
// icons
import { } from 'ionicons/icons';

// components
import { IonList, IonItem, IonThumbnail, IonSkeletonText, IonLabel, } from '@ionic/vue';

export default {
  props: [
    "numOfItems",
  ],
  components: { IonList, IonItem, IonThumbnail, IonSkeletonText, IonLabel, },
  setup() {
    return {
      // icons

      // methods
    }
  }
}
