import parentTranslationMessages from '@/app-template/lib/i18n';

export default {
  zh: {
    ...parentTranslationMessages.zh,
    // add extra zh translations below
    new: '新',
    registeringAccount: '設定帳戶中...',
    loginInformation: '登入帳號資料',
    successUpdatePersonalInfo: '已成功更新個人資料。',
    clashEmailAddress: '此電子郵件地址已被使用。',
    wrongPassword: '登入資料錯誤！請重新輸入。',
    editMobileNumber: '更改手機號碼',
    searchOrAdd: '搜尋或新增',
    select: '選擇',
    petBreed: '寵物品種',

    creditCard: '網上信用卡即時付款 (建議)',
    offlinePayment: '銀行過數 / FPS / PayMe',
    
    home: '首頁',
    merchant: '商家',
    allMerchants: '所有商家',
    latestPosts: '最新資訊',
    readMore: '閱讀更多',
    search: '搜尋',
    myDesigns: '我的設計',
    myOrders: '我的訂單',

    shareProduct: '分享給朋友',
    earliestDeliveryDate: '最早可取貨日期',
    pickupPoints: '自取點',
    deliveryDistricts: '可送貨地區',
    '港島': '港島',
    '九龍': '九龍',
    '新界': '新界',
    '離島': '離島',
    shareWithFriends: '分享給朋友',
    productIntro: '產品介紹',
    orderNotice: '預訂須知',
    productReviews: '買家評論',
    orderForm: '訂購表格',
    selectOption: '請選擇',
    goToCart: '前往購物車',
    updateCart: '更新購物車',
    successUpdatedCart: '成功更新購物車。',
    customizedProduct: '訂製產品',
    required: '必填',
    delete: '刪除',
    buyNow: '結賬',

    deliveryMethod: '送貨方式：',
    deliveryDistrict: '送貨地區：',
    deliveryAddress: '送貨地址：',
    customerName: '收貨人名字：',
    contactPhone: '聯絡電話：',
    customerEmail: '電郵地址：',

    CartItemModal: {
      productQuestions: '產品訂製資料',
      deliveryInfo: '送貨資料',
      deliveryDate: '送貨日期',
      deliveryTime: '送貨時間',
      pickupDate: '取貨日期',
      pickupTime: '取貨時間',
      contactInfo: '聯絡人資料',
      selectDistrict: '請選擇送貨地區',
      enterDeliveryAddress: '請輸入送貨地址',
      enterCustomerName: '請輸入收貨人名字',
      enterContactPhone: '請輸入聯絡電話',
      enterCustomerEmail: '請輸入電郵地址',
      pickup: '自取',
      delivery: '送貨上門',
      pickupPoint: '自取點',
      selectPickupPoint: '選擇自取點',
    },
    MerchantPage: {
      allProducts: '所有產品',
      numOfProducts: '商品數量',
      follow: '追蹤',
      unfollow: '取消追蹤',
      share: '分享',
    },
    ProductPage: {
      ...parentTranslationMessages.zh.ProductPage,
      noCustomerReviews: '暫時未有買家評論',
      relatedProducts: '品牌相關推介',
      search: '搜尋商品或品牌',
    },
    HomePage: {
      ...parentTranslationMessages.zh.HomePage,
      home: '首頁',
      productCategories: '分類',
      specialOffers: '限時優惠',
      recentBrowse: '最近瀏覽',
      featuredMerchants: '特色品牌',
      featuredFood: '特色食品',
      latestPosts: '最新資訊',
    },
    FeedbackPage: {
      ...parentTranslationMessages.zh.FeedbackPage,
      phone: '您的手機號碼',
      enterPhone: '請輸入您的手機號碼',
    },
    LoginPage: {
      ...parentTranslationMessages.zh.LoginPage,
      loginEmail: '手機號碼 / 電郵地址',
      loginWithVerificationCode: '手機驗證碼登入',
      scanLocationQRCode: '掃描地盤二維碼註冊',
      sendVerificationCode: '發送驗證碼',
    },
    RegisterPage: {
      ...parentTranslationMessages.zh.RegisterPage,
      phone: '手機號碼',
      email: '電郵地址 (可選填)',
      enterPhoneAndPassword: '請輸入手機號碼及密碼。',
      enterValidHKMobileNumber: '請輸入有效的香港手機號碼 (8位數字)',
      verifyingMobileNumber: '驗證閣下的手機號碼',
      verifyMobileNumberInstruction: '請輸入短訊驗證碼，驗證碼已發送至您的手機',
      verificationCode: '短訊驗證碼',
      invalidVerificationCode: '無效短訊驗證碼！',
      verify: '驗證',
      phoneRegistered: '此手機號碼已被註冊'
    },
    UserProfilePage: {
      ...parentTranslationMessages.zh.UserProfilePage,
      name: '姓名',
      liked: '我的喜愛清單',
      likedProducts: '喜愛產品',
      likedMerchants: '喜愛品牌',
    },
    CheckoutPage: {
      ...parentTranslationMessages.zh.CheckoutPage,
      confirmPayment: '確認下單',
      confirmOrder: '確認下單?',
      discountCode: '折扣碼',
      subTotal: '小計：',
      discountTotal: '折扣：',
      shippingTotal: '運費：',
      orderTotal: '總計：',
      invalidDiscountCode: '無效折扣碼。',
      delivery: '送貨 (運費到付)',
      pickup: '指定地點交收',
      pickupPoint: '交收地點',
      selectPickupPoint: '選擇交收地點',
      estimatedDeliveryDate: '預計交貨日期：',
      agreeTC: '點擊「確認下單」即表示我已閱讀並接受',
      orderTC: '下單條款及細則',
      paymentMethod: '付款方式',
      paymentInfo: '付款資料',
    },
    OrderPage: {
      ...parentTranslationMessages.zh.OrderPage,
      petPhotos: '寵物相片',
    },
  },
  en: {
    ...parentTranslationMessages.en,
    // add extra en translations below
    new: 'New',
    registeringAccount: 'Setting up account...',
    loginInformation: 'Login Account Information',
    successUpdatePersonalInfo: 'Personal information updated successfully.',
    clashEmailAddress: 'The email address is already in use by another account.',
    wrongPassword: 'Login information is incorrect.',
    editMobileNumber: 'Edit Mobile Number',
    searchOrAdd: 'Search / Add New',
    select: 'Select',
    petBreed: ' Pet Breed',

    creditCard: 'Credit Card',
    offlinePayment: 'Bank Transfer / FPS / PayMe',

    home: 'Home',
    merchant: 'Sellers',
    allMerchants: 'All Sellers',
    latestPosts: 'Latest News',
    readMore: 'Read More',
    search: 'Search',
    myDesigns: 'My Designs',
    myOrders: 'My Orders',

    shareProduct: 'Share',
    earliestDeliveryDate: 'Earlist Delivery Date',
    pickupPoints: 'Pick-up Points',
    deliveryDistricts: 'Delivering Districts',
    hki: 'HKI',
    kln: 'KLN',
    nt: 'NT',

    '港島': 'HKI',
    '九龍': 'KLN',
    '新界': 'NT',
    '離島': 'ISL',
    shareWithFriends: 'Share',
    productIntro: 'Introduction',
    orderNotice: 'Order Notice',
    productReviews: 'Product Reviews',
    orderForm: 'Order Form',
    selectOption: 'Please select',
    goToCart: 'Go to Cart',
    updateCart: 'Update Cart',
    successUpdatedCart: 'Cart updated successfully.',
    customizedProduct: 'Customized',
    required: 'Required',
    delete: 'Delete',

    deliveryMethod: 'Delivery Method: ',
    deliveryDistrict: 'Delivery District: ',
    deliveryAddress: 'Delivery Address: ',
    customerName: 'Customer Name: ',
    contactPhone: 'Contact Phone: ',
    customerEmail: 'Email Address: ',

    CartItemModal: {
      productQuestions: 'Product Customization',
      deliveryInfo: 'Delivery Information',
      deliveryDate: 'Delivery Date',
      deliveryTime: 'Delivery Time',
      pickupDate: 'Pick-up Date',
      pickupTime: 'Pick-up Time',
      contactInfo: 'Contact Information',
      selectDistrict: 'Select the delivery district',
      enterDeliveryAddress: 'Enter the delivery address',
      enterCustomerName: 'Enter your name',
      enterContactPhone: 'Enter the contact phone',
      enterCustomerEmail: 'Enter the email address',
      pickup: 'Pick up',
      delivery: 'Delivery',
      pickupPoint: 'Pick-up Point',
      selectPickupPoint: 'Select pick-up point',
    },
    MerchantPage: {
      allProducts: 'All',
      numOfProducts: 'Total Products',
      follow: 'Follow',
      unfollow: 'Unfollow',
      share: 'Share',
    },
    ProductPage: {
      ...parentTranslationMessages.en.ProductPage,
      noCustomerReviews: 'No Customer Reviews',
      relatedProducts: 'Related Products',
      search: 'Search products or brands',
    },
    HomePage: {
      ...parentTranslationMessages.en.HomePage,
      home: 'Home',
      productCategories: 'Categories',
      specialOffers: 'Special Offers',
      recentBrowse: 'Recently Watched',
      featuredMerchants: 'Featured Brands',
      featuredFood: 'Featured Food',
      latestPosts: 'Latest News',
    },
    FeedbackPage: {
      ...parentTranslationMessages.en.FeedbackPage,
      phone: 'Your phone',
      enterPhone: 'Enter your phone',
    },
    LoginPage: {
      ...parentTranslationMessages.en.LoginPage,
      loginEmail: 'Mobile Number / Email',
      loginWithVerificationCode: 'Log in with SMS Verification Code',
      scanLocationQRCode: 'Register with QR Code',
      sendVerificationCode: 'Send Verification Code',
    },
    RegisterPage: {
      ...parentTranslationMessages.en.RegisterPage,
      phone: 'Mobile Number',
      email: 'Email (optional)',
      enterPhoneAndPassword: 'Please enter both the mobile number and password.',
      enterValidHKMobileNumber: 'Please enter a valid Hong Kong mobile number (8 digits)',
      verifyingMobileNumber: 'Verify your mobile number',
      verifyMobileNumberInstruction: 'Please enter the verification code that was sent to your mobile device',
      verificationCode: 'Verification Code',
      invalidVerificationCode: 'Invalid verification code!',
      verify: 'Verify',
      phoneRegistered: 'This phone has already been registered'
    },
    UserProfilePage: {
      ...parentTranslationMessages.en.UserProfilePage,
      name: 'Name',
      liked: 'My List',
      likedProducts: 'Liked Products',
      likedMerchants: 'Liked Brands',
    },
    CheckoutPage: {
      ...parentTranslationMessages.en.CheckoutPage,
      confirmPayment: 'Submit',
      confirmOrder: 'Confirm Placing Order?',
      discountCode: 'Discount Code',
      subTotal: 'Subtotal: ',
      discountTotal: 'Discount: ',
      shippingTotal: 'Shipping Total: ',
      orderTotal: 'Grand Total: ',
      invalidDiscountCode: 'Invalid Discount Code!',
      delivery: 'Delivery',
      estimatedDeliveryDate: 'Estimated Delivery Date: ',
      agreeTC: 'By clicking "Submit" I agree',
      orderTC: 'the Order Terms & Conditions',
      paymentMethod: 'Payment Method',
      paymentInfo: 'Payment Info',
    },
    OrderPage: {
      ...parentTranslationMessages.en.OrderPage,
      petPhotos: 'Pet Photos',
    },
  }
}