import firebase from 'firebase/app';
import 'firebase/functions';
import parentCartService from '@/app-template/lib/services/CartService';

export default {
  ...parentCartService,

  // READ
  async getUserCartItems() {
    return (await firebase.app().functions('asia-east2').httpsCallable('cart-getUserCartItems')({ })).data;
  },

  // UPDATE
  async updateUserCartItem(cartItemId: any, quantity: number) {
    return await firebase.app().functions('asia-east2').httpsCallable('cart-updateUserCartItem')({ cartItemId, quantity });
  },
  async addNewCartItem(designId: any, cartItem: any) {
    return await firebase.app().functions('asia-east2').httpsCallable('cart-addNewCartItem')({ designId, });
  },
  async syncCartItems(newItems: any = [], updatedItems: any = []) {
    return await firebase.app().functions('asia-east2').httpsCallable('cart-syncCartItems')({ newItems, updatedItems });
  }
}