
import { computed, ref, watch } from 'vue';

import { IonPage, IonTabBar, IonTabButton, IonTabs, IonLabel, IonIcon, IonBadge, IonRouterOutlet, } from '@ionic/vue';
import PageHeader from '@/components/PageHeader.vue';

import { useRoute } from 'vue-router';
import { useI18n } from 'vue-i18n';
import { useStore } from 'vuex';

import config from '@/config';

export default {
  name: 'Tabs',
  components: { IonPage, IonTabBar, IonTabButton, IonTabs, IonLabel, IonIcon, IonBadge,
                IonRouterOutlet, PageHeader, },
  setup() {
    const store = useStore();
    const route = useRoute();
    const { t } = useI18n();

    const hideTabs = ref(false);
    const numOfCartItems = computed(() => store.getters.numOfCartItems);

    const checkHideTabs = (routePath: any) => {
      hideTabs.value = (
        //routePath.split("/").length > 2 || config.authPages.includes(routePath)
        config.authPages.includes(routePath) || config.hideBottomTabsPageNames.includes((route.name || "").toString())
      );
    }
    watch(() => route.path, (newPath) => {
      checkHideTabs(newPath);
    })
    checkHideTabs(route.path); // triggered on first enter APP

    return {
      t,

      hideTabs, numOfCartItems,

      tabs: config.tabs,
    }
  }
}
