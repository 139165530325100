import parentUserService from '@/app-template/lib/services/UserService';
import firebase from 'firebase/app';
import 'firebase/functions';
import { getPlatforms } from '@ionic/vue';

export default {
  ...parentUserService,

  async createNewUser(newUser: any) {
    newUser.devicePlatforms = getPlatforms().join(", ");
    const res = await firebase.app().functions('asia-east2').httpsCallable('user-createNewUser')(newUser);
    return res.data[0]; // return the new user
  },
  async updateLoggedInUser(updatedUser: any, oldUser: any) {
    const res = await firebase.app().functions('asia-east2').httpsCallable('user-updateLoggedInUser')({ updatedUser, oldUser });
    return res.data[0]; // return the updated user
  },

  // USER BROWSED PRODUCT HISTORY
  async addUserBrowsedProduct(productId: any) {
    return await firebase.app().functions('asia-east2').httpsCallable('user-addUserBrowsedProduct')({ productId });
  },
  async getUserBrowsedProducts() {
    return (await firebase.app().functions('asia-east2').httpsCallable('user-getUserBrowsedProducts')()).data;
  }
}