export function helpers() {
  const uniqueId = () => Math.random().toString(36).slice(-8);
  const arraymove = (arr: any, fromIndex: any, toIndex: any) => {
    const element = arr[fromIndex];
    arr.splice(fromIndex, 1);
    arr.splice(toIndex, 0, element);
  }
  return {
    uniqueId,
    arraymove,
    sleep: (s: any) => {
      return new Promise((resolve) => {
        setTimeout(resolve, s * 1000);
      });
    },
    numberWithCommas: (x: any) => {
      return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },
  };
}