export default {
  // DIY App
  banners: [],
  orders: [],
  pickupPoints: [], // MTR stations / 1501
  bottles: [],
  allPets: [], // for pet select modal
  pets: [],
  flowers: [],
  userDesigns: [],
  loadingUserDesigns: true,
  loadingPublicData: true,
  loadingOrders: false,

  newDesign: {
    numOfPets: 0,
    withPets: null,
    pet1: null,
    pet2: null,
    pet3: null,
    bottle: {},
  },

  settings: {},

  /*--- BELOW FOR REFERENCES ONLY ---*/
  maxDescriptionLen: 50, // threshold for showing read more button in Product Detail page
  
  bookingServices: [],
  fetchedBookingServices: false,
  
  cartItems: [],
  loadingCartItems: true,

  // customized state variables
  loadingMerchants: true,
  allMerchants: [],

  loadingDistricts: true,
  allDistricts: [],

  loadingDeliveryOptions: true,
  deliveryOptions: [],

  allPostCategories: true,

  loadingHomeSections: true,
  homeSections: [],

  productQuestions: [],
}