import firebase from 'firebase/app';
import 'firebase/storage';
import moment from 'moment';
import Compressor from 'compressorjs'; // for compressing image size before upload

export const uniqueId = () => Math.random().toString(36).slice(-8);

export const uploadImage = async (imageBase64Data: any, imageTitle: string, targetTable = 'design', noTimestamp = false) => {
  // compress the image
  const response = await fetch(imageBase64Data);
  const blob = await response.blob();
  const imageFile: any = await new Promise((resolve, reject) => {
    new Compressor(blob, {
      maxWidth: 1500,
      quality: 0.6,
      success: resolve,
      error: reject,
    });
  });
  // upload the post image file to Firebase Storage
  const extension = imageBase64Data.split(';')[0].split('/')[1];
  //const fileName = `${moment().format('YYYYMMDDHHmmss')}-${imageTitle.substring(0, 10)}.${extension}`; // WILL CAUSE COLLISION
  let fileName = `${imageTitle.substring(0, 10)}.${extension}`;
  if (!noTimestamp) fileName = `${new Date().valueOf()}-${fileName}`;
  const snapshot = await firebase.storage().ref(`${targetTable}-${fileName}`).put(imageFile);
  return await snapshot.ref.getDownloadURL();
}

export const uploadPaymentProof = async (file: any, customerName: string) => {
  // compress the image
  file = await new Promise((resolve, reject) => {
    new Compressor(file, {
      maxWidth: 1500,
      quality: 0.6,
      success: resolve,
      error: reject,
    });
  });
  // upload the post image file to Firebase Storage
  const extension = file.name.split('.').pop();
  const fileName = `${moment().format('YYYYMMDDHHmmss')}-${customerName.substring(0, 10)}.${extension}`;
  const snapshot = await firebase.storage().ref(`orderPaymentProof-${fileName}`).put(file);
  return await snapshot.ref.getDownloadURL();
}