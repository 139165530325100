export default {
  // customized mutations

  // Orders
  setLoadingOrders(state, isLoading) {
    state.loadingOrders = isLoading;
  },
  receiveUserOrders(state, orders) {
    state.orders = orders.map(o => {
      if (!Array.isArray(o.photoLinks)) {
        o.photoLinks = (o.photoLinks || "").split(" , ").filter(link => !!link);
      }
      o.productName = o.productName || `手作寵物LED保鮮花瓶 - ${o['樽']}${o['總寵物數量']}隻寵物`;
      return o;
    });
    state.loadingOrders = false;
  },
  addNewUserOrders(state, orders) {
    orders.forEach(order => {
      const existingOrder = state.orders.find(o => o.id == order.id);
      if (existingOrder == null) {
        order.productName = order.productName || `手作寵物LED保鮮花瓶 - ${order['樽']}${order['總寵物數量']}隻寵物`;
        if (!Array.isArray(order.photoLinks)) {
          order.photoLinks = (order.photoLinks || "").split(" , ").filter(link => !!link);
        }
        state.orders.push(order);
      }
    });
    state.loadingOrders = false;
  },

  // Public data
  receivePublicData(state, { flowers, flowerColors, colors, bottles, pets, settings, pickupPoints, banners, allPets, }) {
    // 保鮮瓶樽
    state.bottles = bottles.map(b => {
      b.name = b['顯示名稱'];
      b.size = b['大小'];
      b.photoLink = b.glassPhotoLink; // bottle display photo
      return b;
    });
    // update bottle info of session new design
    if (state.newDesign.bottle && state.newDesign.bottle.id) {
      state.newDesign.bottle = state.bottles.find(b => b.id == state.newDesign.bottle.id);
    }

    // standard pets (names & demo images)
    state.allPets = allPets;
    state.pets = pets.filter(p => {
      p.width = p.width ? Number(p.width) : "";
      p.height = p.height ? Number(p.height) : "";
      p.name = p['簡稱'];
      p.breed = p['品種'];
      p.category = p['類別'];
      p.color = p['顏色'];
      return p['顯示在App?'] == 'Y';
    });

    // Flowers
    state.flowers = flowers.filter(f => {
      f.width = f.width ? Number(f.width) : "";
      f.height = f.height ? Number(f.height) : "";
      f.originalWidth = f.originalWidth ? Number(f.originalWidth) : "";
      f.originalHeight = f.originalHeight ? Number(f.originalHeight) : "";
      f.heightWithoutStem = f.heightWithoutStem ? Number(f.heightWithoutStem) : "";
      f.type = f['種類'];
      f.name = f['簡稱'];
      f.sellingPrice = f['售價'];
      f.colors = flowerColors.filter(fc => fc.flowerId == f.id);
      return f['顯示在App?'] == 'Y';
    });

    // Settings
    state.settings = settings;

    // Pickup Points
    state.pickupPoints = pickupPoints;

    // Banners
    state.banners = banners;

    state.loadingPublicData = false;
  },

  // Designs
  setLoadingUserDesigns(state, isLoading) {
    state.loadingUserDesigns = isLoading;
  },
  updateDesign(state, { designId, updatedObj }) {
    if (designId) {
      const design = state.userDesigns.find(d => d.id == designId);
      if (design) {
        for (const key in updatedObj) design[key] = updatedObj[key];
      }
    } else {
      for (const key in updatedObj) state.newDesign[key] = updatedObj[key];
    }
  },
  resetNewDesign(state) {
    state.newDesign = {
      numOfPets: 0,
      withPets: null,
      pet1: null,
      pet2: null,
      pet3: null,
      bottle: {},
    };
  },
  receiveNewDesign(state, data) {
    state.newDesign = data;
  },
  receiveUserDesigns(state, data) {
    const { designs, designPets } = data;
    state.userDesigns = designs.map(design => {
      design.withPets = design.withPets == 'Y';
      design.canvas = design.canvasJson ? JSON.parse(design.canvasJson) : {};
      design.pet1 = designPets.find(dp => dp.id == design.pet1Id);
      design.pet2 = designPets.find(dp => dp.id == design.pet2Id);
      design.pet3 = designPets.find(dp => dp.id == design.pet3Id);
      design.bottle = state.bottles.find(b => b.id == design.bottleId);
      return design;
    });
    state.userDesignPets = designPets;
    state.loadingUserDesigns = false;
  },
  addNewUserDesigns(state, data) {
    const { designs, designPets } = data;
    designs.forEach(design => {
      const existingDesign = state.userDesigns.find(d => d.id == design.id);
      if (existingDesign == null) {
        design.withPets = design.withPets == 'Y';
        design.canvas = design.canvasJson ? JSON.parse(design.canvasJson) : {};
        design.pet1 = designPets.find(dp => dp.id == design.pet1Id);
        design.pet2 = designPets.find(dp => dp.id == design.pet2Id);
        design.pet3 = designPets.find(dp => dp.id == design.pet3Id);
        design.bottle = state.bottles.find(b => b.id == design.bottleId);
        state.userDesigns.push(design);
      }
    });
    state.loadingUserDesigns = false;
  },
  upsertUserDesign(state, design) {
    const idx = state.userDesigns.findIndex(d  => d.id == design.id);
    if (idx !== -1) {
      state.userDesigns.splice(idx, 1, design);
    } else {
      state.userDesigns.push(design);
    }
  },
  deleteDesign(state, designId) {
    const idx = state.userDesigns.findIndex(d => d.id == designId);
    state.userDesigns.splice(idx, 1);
  },

  // REFERENCES
  receiveHomeSections(state, data) {
    state.homeSections = data;
    state.loadingHomeSections = false;
  },
  
  receiveCompanyInfo(state, data) {
    state.maxDescriptionLen = Number(data.readMoreThreshold) || 50;
    state.companyInfo = data;
    state.loadingCompanyInfo = false;
  },

  // USER
  receiveUser(state, user) {
    const userId = user.id;
    state.allProducts.forEach(product => {
      // check if the product is liked by the logged in user
      product.purchasedByUser = userId ? product.relatedUserPurchasedItems.includes(userId) : false;
      product.likedByUser = userId ? product.relatedUserLikedItems.includes(userId) : false;
    });
    state.allMerchants.forEach(m => {
      m.likedByUser = userId ? m.relatedUserLikedMerchants.includes(userId) : false;
    });
    state.user = user;
    state.loadingUser = false;
    state.loggedIn = (userId ? true : false);
  },

  // PRODUCT
  receiveAllProducts(state, allProducts) {
    const userId = state.user.id;
    allProducts.forEach(p => {
      p.purchasedByUser = userId ? p.relatedUserPurchasedItems.includes(userId) : false;
      p.likedByUser = userId ? p.relatedUserLikedItems.includes(userId) : false;
      p.photoLinks = p.photoLinks ? p.photoLinks.split(" , ") : [];
      p.inStock = p.inStock == 'Y';
    });
    state.allProducts = allProducts;
    state.loadingProducts = false;
  },
  receiveProductQuestions(state, { productQuestions, productQuestionOptions }) {
    productQuestions.forEach(q => {
      q.isRequired = q.isRequired == 'Y';
      q.cbShowTextbox = q.cbShowTextbox == 'Y';
      q.options = productQuestionOptions.filter(opt => opt.questionId == q.id);
    });
    state.productQuestions = productQuestions;
  },
  addUserBrowsedProduct(state, productId) {
    let currUsrProductIds = state.user.relatedUserBrowsedProducts || "";
    if (currUsrProductIds == "") currUsrProductIds = [productId]; // first product
    else {
      currUsrProductIds = currUsrProductIds.split(" , ").filter(usrProductId => !usrProductId.includes(productId));
      currUsrProductIds.unshift(productId);
    }
    state.user.relatedUserBrowsedProducts = currUsrProductIds.join(" , ");
  },

  // MERCHANTS
  receiveAllMerchants(state, allMerchants) {
    const userId = state.user.id;
    allMerchants.forEach(m => {
      m.likedByUser = userId ? m.relatedUserLikedMerchants.includes(userId) : false;
    });
    state.allMerchants = allMerchants;
    state.loadingMerchants = false;
  },

  // DELIVERY OPTIONS
  receiveDeliveryOptions(state, data) {
    state.deliveryOptions = data;
    state.loadingDeliveryOptions = false;
  },

  // DISTRICTS
  receiveAllDistricts(state, data) {
    state.allDistricts = data;
    state.loadingDistricts = false;
  },

  // CART ITEMS
  loadingCartItems(state) {
    state.loadingCartItems = true; 
  },
  receiveCartItems(state, cartItems) {
    state.cartItems = cartItems;
    state.loadingCartItems = false;
  },
  upsertCartItem(state, { cartItemObj, checkField = 'designId' }) {
    const idx = state.cartItems.findIndex(item => item[checkField] == cartItemObj[checkField]);
    if (idx !== -1) {
      state.cartItems.splice(idx, 1, cartItemObj);
    } else {
      state.cartItems.push(cartItemObj);
    }
  },

  // POST CATEGORIES
  receivePostCategories(state, data) {
    state.allPostCategories = data;
  },
}