import { createStore, createLogger } from 'vuex';

import parentState from '@/app-template/lib/store/state';
import parentMutations from '@/app-template/lib/store/mutations';
import * as parentActions from '@/app-template/lib/store/actions';
import * as parentGetters from '@/app-template/lib/store/getters';

import state from './state';
import mutations from './mutations';
import * as actions from './actions';
import * as getters from './getters';

export default createStore({
  state: { ...parentState, ...state },
  getters: { ...parentGetters, ...getters },
  actions: { ...parentActions, ...actions },
  mutations: { ...parentMutations, ...mutations },
  plugins: process.env.NODE_ENV !== 'production'
    ? [createLogger()]
    : []
})