// customized actions
import OrderService from '@/services/OrderService';
import CartService from '@/services/CartService';
import DesignService from '@/services/DesignService';
import MerchantService from '@/services/MerchantService';
import { helpers } from '@/composables/helpers';

export const linkSessionOrdersToUser = ({ state, getters, commit }, { userId }) => {
  if (state.orders.length > 0) {
    OrderService.batchUpdateOrders({
      orders: state.orders.map(d => ({ 'id': d.id, 'user_id': userId })),
    });
  }
}

export const linkSessionDesignsToUser = ({ state, getters, commit }, { userId }) => {
  if (state.userDesigns.length > 0) {
    DesignService.batchUpdateDesigns({
      designs: state.userDesigns.map(d => ({ 'id': d.id, 'user_id': userId })),
    });
  }
}
export const updateDBDesignPreQuestionAnswers = ({ state, getters, commit }, { design }) => {
  const { uniqueId, } = helpers();

  const { id: designId, pet1, pet2, pet3, bottle, withPets, } = design;
  let { pet1Id, pet2Id, pet3Id, } = design;
  const numOfPets = [pet1, pet2, pet3].filter(p => !!p).length;

  pet1Id = pet1Id || uniqueId();
  pet2Id = pet2Id || uniqueId();
  pet3Id = pet3Id || uniqueId();

  // add design pets
  DesignService.upsertDesignPets([
    { id: pet1Id, designId, petTypeId: pet1 ? pet1.petTypeId : "" },
    { id: pet2Id, designId, petTypeId: pet2 ? pet2.petTypeId : "" },
    { id: pet3Id, designId, petTypeId: pet3 ? pet3.petTypeId : "" },
  ]);

  // update design (pet1, pet2, pet3)
  DesignService.updateDesign({ designId, updatedObj: {
    'with_pets': withPets,
    'bottle_id': bottle.id,
    'num_of_pets': numOfPets,
    'pet1_id': pet1Id,
    'pet2_id': pet2Id,
    'pet3_id': pet3Id,
  } });

  commit('updateDesign', { designId, updatedObj: { pet1Id, pet2Id, pet3Id } });
}

export const addDesignToCart = ({ state, getters, commit }, { design }) => {
  const existingCartItem = getters.getCartItem(design.id);
  if (existingCartItem) { // update existing cart item
    const newQty = +existingCartItem.quantity + 1;
    commit('upsertCartItem', {
      cartItemObj: {
        ...existingCartItem,
        quantity: newQty,
      }
    });
    if (state.loggedIn) {
      CartService.updateUserCartItem(existingCartItem.id, newQty); // update DB cart item
    }
  } else { // add new cart item
    commit('upsertCartItem', { designId: design.id });
    if (state.loggedIn) {
      CartService.addNewCartItem(design.id); // update DB cart item
    }
  }
}

export const updateLikedMerchant = ({ state, getters, commit }, { merchantId, action }) => {
  if (action == 'add') MerchantService.addUserLikedMerchant(merchantId);
  else MerchantService.removeUserLikedMerchant(merchantId);
}

export const addProductToCart = ({ state, getters, commit }, { product, cartItem, variant = {}, productQuestions = [] }) => {
  const baseItemData = {
    productId: product.id,
    productImage: product.imageLink, // to be replaced by AppSheet virtual columns
    productName: product.title, // to be replaced by AppSheet virtual columns
    variantId: variant.id
  }
  if (productQuestions.length > 0) { // customizable products: always create new cart items
    if (cartItem.id == null) cartItem.id = Math.random().toString(36).substr(2, 8); // random ID as the key
    commit('upsertCartItem', {
      cartItemObj: {
        ...baseItemData,
        ...cartItem,
      },
      checkField: 'id',
    });
    if (state.loggedIn) {
      CartService.addNewCartItem(product.id, cartItem, variant.id, productQuestions); // update DB cart item
    }
  } else { // non-customizable products
    const existingCartItem = getters.getCartItem(product.id, variant.id);
    if (existingCartItem) { // update existing cart item
      const newQty = +existingCartItem.quantity + cartItem.quantity;
      commit('upsertCartItem', {
        cartItemObj: {
          ...existingCartItem,
          ...cartItem,
          quantity: newQty,
        }
      });
      if (state.loggedIn) {
        CartService.updateUserCartItem(existingCartItem.id, newQty, variant.id); // update DB cart item
      }
    } else { // add new cart item
      commit('upsertCartItem', {
        cartItemObj: {
          ...baseItemData,
          ...cartItem,
        }
      });
      if (state.loggedIn) {
        CartService.addNewCartItem(product.id, cartItem, variant.id); // update DB cart item
      }
    }
  }
}