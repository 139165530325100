import parentOrderService from '@/app-template/lib/services/OrderService';
import firebase from 'firebase/app';
import 'firebase/functions';
import 'firebase/storage';
import { getPlatforms } from '@ionic/vue';
import { uploadImage, } from '@/services/utils';
import { Photo } from '@/composables/usePhotoGallery';

export default {
  ...parentOrderService,

  async getOrdersByIds(orderIds: any) {
    return (await firebase.app().functions('asia-east2').httpsCallable('order-getOrdersByIds')({ orderIds })).data;
  },

  async getDiscount(discountCode: any) {
    return (await firebase.app().functions('asia-east2').httpsCallable('order-getDiscount')({ discountCode })).data;
  },

  async createPaymentIntent(currency: any, amount: any, paymentMethodType: any) {
    return (await firebase.app().functions('asia-east2').httpsCallable('order-createPaymentIntent')({ currency, amount, paymentMethodType })).data;
  },

  async createNewOrder(order: any, cartItems: any, petPhotos: Photo[], emptyCart = false) {
    const petPhotoLinks = [];
    if (petPhotos) {
      for (const photo of petPhotos) {
        const link = await uploadImage(photo.base64Data, 'PetPhoto', 'order');
        petPhotoLinks.push(link);
      }
    }
    for (const item of cartItems) {
      item.designPhotoLink = await uploadImage(item.designDataURL || item.selectedSet.previewImage, item.designName || 'DesignPhoto', 'order');
      console.log(item.designPhotoLink);
    }
    order.sourceName = getPlatforms().join(", ");
    const res = await firebase.app().functions('asia-east2').httpsCallable('order-createNewOrder')({ order, cartItems, petPhotoLinks, emptyCart });
    return res.data;
  }
}