import CartService from '@/services/CartService';
import UserService from '@/services/UserService';

export const updateUserLastCheckNotificationTime = ({ state, commit }) => {
  state.user.lastCheckNotificationsAt = new Date();
  UserService.updateLastCheckNotificationTime().then(res => {
    commit('receiveUser', res);
  });
}

export const addProductToCart = ({ state, getters, commit }, { product, addQty = 1, variant = {} }) => {
  const existingCartItem = getters.getCartItem(product.id, variant.id);
  if (existingCartItem) { // update existing cart item
    const newQty = +existingCartItem.quantity + addQty;
    commit('upsertCartItem', {
      ...existingCartItem,
      quantity: newQty,
    });
    if (state.loggedIn) {
      return CartService.updateUserCartItem(product.id, newQty, variant.id).then(res => {
        commit('upsertCartItem', res.data[0]);
      });
    }
  } else { // add new cart item
    commit('upsertCartItem', {
      productType: product.type,
      productId: product.id,
      productImage: product.imageLink,
      productName: product.title,
      quantity: addQty,
      unitPrice: variant.price || product.sellingPrice, // if selected product variant use its price instead
      variantId: variant.id,
      variantTitle: variant.title,
    });
    if (state.loggedIn) {
      return CartService.addNewCartItem(product.id, addQty, variant.id).then(res => {
        commit('upsertCartItem', res.data[0]);
      });
    }
  }
}

export const createNewUser = ({ commit }, newUser) => {
  UserService.createNewUser(newUser).then(newUserRow => {
    commit('receiveUser', newUserRow); // this updates projects related to user as well
  });
}

export const resetUserData = ({ commit }) => {
  commit('receiveUser', {}); // empty the store user object
  commit('receiveCartItems', []);
  commit('receivePurchasedServices', []);
  commit('receiveBookings', []);
}