import firebase from 'firebase/app';
import 'firebase/functions';

export default {
  // User Liked Merchants
  async addUserLikedMerchant(merchantId: any) {
    return await firebase.app().functions('asia-east2').httpsCallable('merchant-addUserLikedMerchant')({ merchantId });
  },
  async removeUserLikedMerchant(merchantId: any) {
    return await firebase.app().functions('asia-east2').httpsCallable('merchant-removeUserLikedMerchant')({ merchantId });
  },
}