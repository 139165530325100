import parentConfig from '@/app-template/lib/config';
import { storefrontOutline, home, calendar, notifications, personOutline, basketOutline, newspaperOutline, settingsOutline, chatbubbleOutline,
        cardOutline, logoGoogle, logoApple, logoPaypal, logoAlipay, logoWechat, businessOutline, business, colorWandOutline,
        receiptOutline, } from 'ionicons/icons';

export default {
  ...parentConfig,

  orderIdsLocalStorageKey: 'flowerOrderIds',
  designIdsLocalStorageKey: 'flowerDesignIds',
  newDesignLocalStorageKey: 'newDesign1',
  
  versionCode: '1.0.0',
  websiteUrl: 'https://crystalputeri.com/',
  enquiryLink: 'https://wa.me/85252222989',
  
  primaryColor: '#0ABAB5',
  logo: require('@/assets/logo.png'),

  Firebase: {
    apiKey: "AIzaSyAunDtegUACKbepJYUVRA8IDn4WFuu9FiA",
    authDomain: "crystalputeri-flower.firebaseapp.com",
    projectId: "crystalputeri-flower",
    storageBucket: "crystalputeri-flower.appspot.com",
    messagingSenderId: "807012629921",
    appId: "1:807012629921:web:ec38f785795627d1e19fe0",
    measurementId: "G-L4EWFDD85Z"
  },

  Stripe: {
    //publishableKey: 'pk_test_51HVue8F5eqkgmghRoS3cNn8SMdn9ou9PIffiirbX1a4BvdgPSgCGHWvX6EO4XQF6ppPZdkFxyJHE8MmwGs2aby8P00g5qBbCG0', // test
    publishableKey: 'pk_live_51HVue8F5eqkgmghRn1B9EOEvN9FLUN1kshEHg7xRYqmP8OT2YaBraba2PVCqLqH6GY2EuaQH3CpcuKFM1uS8QdFu00W7JYQlu1', // live
  },

  OneSignal: {
    appId: 'cab81b16-51b1-491f-842d-36b24ffe28bd',
  },

  HomePage: {
    banner: {
      slidingSpeed: 1000, // Duration of transition between slides (in ms)
      autoPlayDelay: 3000, // Delay between transitions (in ms)
      desktopSlidesPerView: 1,
    }
  },

  authPages: [...parentConfig.authPages],
  memberPages: ['/profile', '/bookings', '/services', '/notifications'],
  hideBottomTabsPageNames: [ 'DesignDetailPage', 'CheckoutPage' ],
  extraRootRoutes: [], // example: { path: '/login', component: () => import('@/pages/LoginPage.vue') }
  extraTabRoutes: [
    {
      path: 'designs',
      name: 'DesignListPage',
      component: () => import('@/pages/DesignListPage.vue'),
    },
    {
      path: 'designs/:id',
      name: 'DesignDetailPage',
      component: () => import('@/pages/DesignDetailPage.vue'),
      alias: ['new-design', '/:id'],
    },
    {
      path: 'new-design/post',
      name: 'DesignPostQuestionPage', // questions about the pets
      component: () => import('@/pages/DesignPostQuestionPage.vue'),
    },
    {
      path: 'home',
      name: 'HomePage',
      component: () => import('@/pages/HomePage.vue'),
    },
  ], // example: { path: 'home', component: () => import('@/pages/HomePage.vue') }

  paymentMethods: [
    { key: "creditCard", value: "card", icon: cardOutline },
    { key: "offlinePayment", value: "bank", icon: businessOutline },
    //{ key: "Google Pay", value: "googlePay", icon: logoGoogle },
    //{ key: "Apple Pay", value: "applePay", icon: logoApple },
    /*{ key: "PayPal", value: "paypal", icon: logoPaypal },
    { key: "AlipayHK", value: "alipayhk", icon: logoAlipay },
    { key: "WeChat Pay HK", value: "wechatpayhk", icon: logoWechat },
    { key: "Bank Transfer", value: "bank", icon: businessOutline },*/
  ],
  
  tabs: [
    { icon: home, slug: 'home', label: 'home' },
    { icon: colorWandOutline, slug: 'designs', label: 'myDesigns' },
    { icon: receiptOutline, slug: 'orders', label: 'myOrders' },
    //{ icon: storefrontOutline, slug: 'products', label: 'shop' },
    //{ icon: basketOutline, slug: 'cart', label: 'cart' },
    //{ icon: newspaperOutline, slug: 'posts', label: 'latestPosts' },
    //{ icon: chatbubbleOutline, slug: 'inquiry', label: 'inquiry' },
    { icon: personOutline, slug: 'profile', label: 'profile' },
  ],
}
