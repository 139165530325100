import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_icon = _resolveComponent("ion-icon")!
  const _component_ion_button = _resolveComponent("ion-button")!
  const _component_ion_buttons = _resolveComponent("ion-buttons")!
  const _component_ion_title = _resolveComponent("ion-title")!
  const _component_ion_toolbar = _resolveComponent("ion-toolbar")!
  const _component_ion_header = _resolveComponent("ion-header")!
  const _component_loading_skeleton = _resolveComponent("loading-skeleton")!
  const _component_ion_thumbnail = _resolveComponent("ion-thumbnail")!
  const _component_ion_label = _resolveComponent("ion-label")!
  const _component_ion_item = _resolveComponent("ion-item")!
  const _component_ion_list = _resolveComponent("ion-list")!
  const _component_ion_grid = _resolveComponent("ion-grid")!
  const _component_ion_content = _resolveComponent("ion-content")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_ion_header, null, {
      default: _withCtx(() => [
        _createVNode(_component_ion_toolbar, null, {
          default: _withCtx(() => [
            _createVNode(_component_ion_buttons, { slot: "start" }, {
              default: _withCtx(() => [
                _createVNode(_component_ion_button, {
                  slot: "icon-only",
                  onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.closeModal()))
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_ion_icon, { icon: _ctx.close }, null, 8, ["icon"])
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }),
            _createVNode(_component_ion_title, null, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.t('notifications')), 1)
              ]),
              _: 1
            })
          ]),
          _: 1
        })
      ]),
      _: 1
    }),
    _createVNode(_component_ion_content, { fullscreen: true }, {
      default: _withCtx(() => [
        _createVNode(_component_ion_grid, { fixed: "" }, {
          default: _withCtx(() => [
            (_ctx.loading)
              ? (_openBlock(), _createBlock(_component_loading_skeleton, { key: 0 }))
              : (_openBlock(), _createBlock(_component_ion_list, { key: 1 }, {
                  default: _withCtx(() => [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.allNotifications, (notification) => {
                      return (_openBlock(), _createBlock(_component_ion_item, {
                        key: notification.id,
                        onClick: ($event: any) => (_ctx.openNotificationDetailModal(notification.id)),
                        button: "",
                        detail: ""
                      }, {
                        default: _withCtx(() => [
                          (notification.photo)
                            ? (_openBlock(), _createBlock(_component_ion_thumbnail, {
                                key: 0,
                                slot: "start"
                              }, {
                                default: _withCtx(() => [
                                  _createElementVNode("img", {
                                    src: notification.photoLink
                                  }, null, 8, _hoisted_1)
                                ]),
                                _: 2
                              }, 1024))
                            : (_openBlock(), _createBlock(_component_ion_icon, {
                                key: 1,
                                icon: _ctx.notificationsOutline,
                                slot: "start"
                              }, null, 8, ["icon"])),
                          _createVNode(_component_ion_label, null, {
                            default: _withCtx(() => [
                              _createElementVNode("h3", null, _toDisplayString(notification.title), 1),
                              _createElementVNode("p", null, _toDisplayString(notification.content), 1),
                              _createElementVNode("p", null, [
                                _createElementVNode("small", null, _toDisplayString(_ctx.getRelativeDate(notification.scheduledToSendAt)), 1)
                              ])
                            ]),
                            _: 2
                          }, 1024)
                        ]),
                        _: 2
                      }, 1032, ["onClick"]))
                    }), 128))
                  ]),
                  _: 1
                }))
          ]),
          _: 1
        })
      ]),
      _: 1
    })
  ], 64))
}