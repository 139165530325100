
// Vue reactivity
import { defineComponent, computed, ref } from 'vue';

// icons
import { close, notificationsOutline } from 'ionicons/icons';

// components
import { IonPage, IonHeader, IonToolbar, IonTitle, IonContent,
        IonGrid, IonList, IonItem, IonLabel, IonIcon,
        IonThumbnail, IonButtons, IonButton, IonBadge,
        IonChip, modalController } from '@ionic/vue';
import LoadingSkeleton from "@/components/LoadingSkeleton.vue";
import NotificationDetailModal from '@/components/templates/NotificationDetailModal.vue';

import { useI18n } from 'vue-i18n';
import { useStore } from 'vuex';
import { utils } from '@/composables/utils';

export default defineComponent({
  name: 'NotificationListModal',
  components: { IonHeader, IonToolbar, IonTitle, IonContent, IonPage,
                IonGrid, IonList, IonItem, IonLabel, IonIcon,
                IonThumbnail, IonButtons, IonButton, IonBadge,
                IonChip, LoadingSkeleton, },
  setup() {
    const { t } = useI18n();
    const store = useStore();
    const { getRelativeDate, openModal, closeModal } = utils();

    // 1. declare state variables (ref to make them reactive)
    const loading = computed(() => store.state.loadingNotifications);
    const allNotifications = computed(() => store.state.allNotifications);

    // 2. methods
    const openNotificationDetailModal = async (id: any) => (openModal(NotificationDetailModal, { id }))
    
    // 3. return variables & methods to be used in template HTML
    return {
      // methods
      t, getRelativeDate, closeModal, openNotificationDetailModal,

      // icons
      close, notificationsOutline,

      // variables
      loading, allNotifications,
    }
  }
})
