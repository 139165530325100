export default {
  pickupPoints: [],

  allNotifications: [],
  loadingNotifications: true,
  
  companyInfo: {},
  loadingCompanyInfo: true,
  
  homeBanners: [],
  user: {},
  loggedIn: false,
  loadingUser: false,

  cartItems: [],
  loadingCartItems: true,

  allProducts: [],
  loadingProducts: true,

  allProductCategories: [],
  loadingProductCategories: true,
  
  allPosts: [],
  loadingPosts: true,

  purchasedServices: [],
  loadingPurchasedServices: true,

  bookings: [],
  loadingBookings: true,
}