import { utils } from '@/composables/utils';
import moment from 'moment-business-days';


// customized getters

// FLOWERS
export const petBottleFlowers = (state) => (state.flowers.filter(f => f["用於產品"] != "寵物芭比娃娃"))
export const petBabeApparels = (state) => (state.flowers.filter(f => f["用於產品"] == "寵物芭比娃娃"))

// BANNERS
export const topBanners = (state) => {
  return state.banners.filter(b => b.image && b.page == 'top').sort((a, b) => a.order - b.order);
}

// ORDERS
export const getOrderById = (state) => (id) => {
  return state.orders.find(o => o.id == id) || {};
}

// 最早可交貨日期
export const estimatedDeliveryDate = (state) => (locale) => {
  moment.updateLocale('zh-hk', {
    holidays: state.settings.holidays.split(" , ").map(h => h.split(" ")[0]),
    holidayFormat: 'M/D/YYYY',
  });
  const format = locale == 'zh' ? 'YYYY年M月D日' : 'YYYY-MM-DD';
  const numOfWorkingDaysRequired = +state.settings["排單預設工作天"] + +state.settings['製作需時工作天'];
  return moment().businessAdd(numOfWorkingDaysRequired, 'days').format(format);
  // TODO: skip public holidays as well
}
export const estimatedDeliveryDateBabe = (state) => (locale) => {
  moment.updateLocale('zh-hk', {
    holidays: state.settings.holidays.split(" , ").map(h => h.split(" ")[0]),
    holidayFormat: 'M/D/YYYY',
  });
  const format = locale == 'zh' ? 'YYYY年M月D日' : 'YYYY-MM-DD';
  const numOfWorkingDaysRequired = +state.settings["芭比娃娃排單預設工作天"] + +state.settings['製作需時工作天'];
  return moment().businessAdd(numOfWorkingDaysRequired, 'days').format(format);
}

// PETS
export const petCategories = (state) => {
  const groupedPets = {};
  for (const pet of state.pets) {
    (groupedPets[pet.category] = groupedPets[pet.category] || []).push(pet);
  }
  return Object.keys(groupedPets); // group pets by categories (貓 / 狗 / ...)
}
export const groupedPets = (state) => {
  const groupedPets = {};
  for (const pet of state.pets) {
    const { breed, category } = pet;
    groupedPets[category] = groupedPets[category] || {};
    (groupedPets[category][breed] = groupedPets[category][breed] || []).push(pet);
  }
  return groupedPets;
}

// DESIGNS
export const getDesignById = (state) => (id) => {
  return state.userDesigns.find(d => d.id == id) || {};
}
export const orderedUserDesigns = (state) => {
  return state.userDesigns.sort((a, b) => {
    const timeA = new Date(a.updatedAt), timeB = new Date(b.updatedAt);
    return timeA > timeB ? -1 : (timeA < timeB ? 1 : 0);
  })
}

// CART ITEMS
export const designCartItems = (state, getters) => {
  const { getDesignItemTotal } = utils();
  return state.cartItems.map(item => {
    const design = getters.getDesignById(item.designId);
    item.canvas = design.canvas;
    item.designDataURL = design.dataURL || "";
    item.unitPrice = getDesignItemTotal(design, design.canvas.shapes);
    return item;
  })
}
export const getCartItem = (state) => (designId) => {
  return state.cartItems.find(item => item.designId == designId);
}
export const cartTotal = (state) => {
  return state.cartItems.reduce((a, b) => +a + (b.unitPrice * b.quantity), 0);
}
export const numOfCartItems = (state) => {
  return state.cartItems.length;
}
export const getGroupedCartItems = (state, getters) => {
  const { formatDate, addDaysToDate, } = utils();
  const groupedObj = {}; // group by merchants
  for (const item of state.cartItems) {
    const merchant = state.allMerchants.find(m => m.relatedProducts.includes(item.productId));
    if (merchant) {
      const product = getters.getProductById(item.productId);
      const productQuestions = getters.getQuestionsByProductId(item.productId);
      const minDeliveryDate = formatDate(addDaysToDate(new Date(), product.minPrepDays), "YYYY-MM-DD");
      if (!(merchant.id in groupedObj)) {
        const pickupPoints = getters.getPickupPointsByMerchantId(merchant.id);
        const groupedDeliveryOptions = getters.getGroupedDeliveryOptionsByMerchantId(merchant.id);
        merchant.deliveryMethod = "送貨上門"; // default delivery method
        merchant.deliveryDate = minDeliveryDate; // default delivery date
        groupedObj[merchant.id] = {
          merchant, pickupPoints, groupedDeliveryOptions,
          cartItems: [], totalPrice: 0, minDeliveryDate,
        };
      }
      if (item.answers) { // server cart items
        const questionAnswers = {}, checkedCbxQuestions = [];
        for (const obj of item.answers) {
          const { questionId, optionIds, answer } = obj;
          const relatedQuestion = productQuestions.find(q => q.id == questionId);
          if (relatedQuestion) {
            if (optionIds) { // select / multi-select
              const selectedOptions = optionIds.split(" , ").map(optionId => relatedQuestion.options.find(opt => opt.id == optionId));
              questionAnswers[questionId] = relatedQuestion.type == 'single-select' ? selectedOptions[0] : selectedOptions;
            } else {
              if (relatedQuestion.type == 'quantity-input') {
                questionAnswers[questionId] = answer; // quantity input question
              } else {
                relatedQuestion.textboxAns = answer; // textarea
                checkedCbxQuestions.push(relatedQuestion); // checkboxes
              }
            }
          }
        }
        item.questionAnswers = questionAnswers;
        item.checkedCbxQuestions = checkedCbxQuestions;
      }
      item.productQuestions = productQuestions;
      groupedObj[merchant.id].cartItems.push(item);
      groupedObj[merchant.id].totalPrice += Number(item.unitPrice * item.quantity);
      if (minDeliveryDate > groupedObj[merchant.id].minDeliveryDate) {
        groupedObj[merchant.id].minDeliveryDate = minDeliveryDate; // use the large min delivery date
        groupedObj[merchant.id].merchant.deliveryDate = minDeliveryDate; // update the selected delivery date
      }
    }
  }
  return groupedObj;
}

// DISTRICT
export const getDistrictById = (state) => (id) => {
  return state.allDistricts.find(d => d.id == id);
}

// PICKUP POINT
export const getPickupPointsByMerchantId = (state) => (merchantId) => {
  return state.pickupPoints.filter(p => p.merchantId == merchantId);
}

// DELIVERY OPTION
export const getGroupedDeliveryOptionsByMerchantId = (state, getters) => (merchantId) => {
  const groupedOptions = {}; // group by region then by group then by price
  const options = state.deliveryOptions.filter(o => o.merchantId == merchantId);
  for (const option of options) {
    const { districts, price } = option;
    const districtIds = districts.split(" , ");
    for (const districtId of districtIds) {
      const district = getters.getDistrictById(districtId);
      if (district) {
        const { region, group } = district;
        if (region && group) {
          groupedOptions[region] = groupedOptions[region] || {};
          groupedOptions[region][group] = groupedOptions[region][group] || {};
          (groupedOptions[region][group][price] = groupedOptions[region][group][price] || []).push(district);
        }
      }
    }
  }
  return groupedOptions;
}

// MERCHANT
export const getMerchantById = (state) => (id) => {
  return state.allMerchants.find(m => m.id == id) || {};
}
export const featuredMerchants = (state) => {
  return state.allMerchants.filter(m => m.isFeatured == "Y");
}
export const latestMerchants = (state) => {
  return state.allMerchants.slice(-5);
}
export const merchantsWithProductCategory = (state) => (category = 'all') => {
  const tmpObj = {};
  for (const product of state.allProducts) {
    const { merchantId, merchantName, categoryId } = product;
    if (merchantId && (category == 'all' || categoryId == category)) {
      if (!(merchantId in tmpObj)) tmpObj[merchantId] = { id: merchantId, name: merchantName, numOfProducts: 0};
      tmpObj[merchantId].numOfProducts++;
    }
  }
  return Object.values(tmpObj);
}
export const userLikedMerchants = (state) => {
  return state.allMerchants.filter(m => m.likedByUser);
}

// PRODUCT & PRODUCT CATEGORY
export const latestProducts = (state) => {
  return state.allProducts.slice(0, 10); // return latest 10 products (reversed in server-side)
}
export const getQuestionsByProductId = (state) => (productId) => {
  return state.productQuestions.filter(q => q.productId == productId);
}
export const getProductsByMerchantId = (state, getters) => (merchantId, excludeProductId) => {
  const productCategories  = {};
  const products = [];
  for (const p of state.allProducts) {
    if (p.merchantId == merchantId) {
      if (excludeProductId && p.id == excludeProductId) continue;
      products.push(p);
      if (p.categoryId) {
        if (!(p.categoryId in productCategories)) {
          const c = getters.getProductCategoryById(p.categoryId);
          productCategories[p.categoryId] = { id: c.id, title: c.title, numOfProducts: 1 };
        } else {
          productCategories[p.categoryId].numOfProducts++;
        }
      }
    }
  }
  return {
    products, categories: Object.values(productCategories),
  }
}
export const getProductCategoryById = (state) => (categoryId) => {
  return state.allProductCategories.find(c => c.id == categoryId);
}
export const userRecentBrowsedProducts = (state) => {
  const { relatedUserBrowsedProducts } = state.user;
  if (relatedUserBrowsedProducts) {
    return relatedUserBrowsedProducts.split(" , ").map(userProductId => {
      return state.allProducts.find(p => userProductId.includes(p.id));
    }).filter(p => !!p);
  }
  return [];
}

// BLOG
export const latestPosts = (state) => {
  return state.allPosts.slice(-5);
}

// HOME SECTIONS
export const getHomeSectionById = (state) => (sectionId) => {
  return state.homeSections.find(s => s.id == sectionId);
}
export const getProductsBySectionId = (state) => (sectionId) => {
  const products = [];
  for (const p of state.allProducts) {
    if (p.relatedHomeSectionProducts.includes(sectionId)) products.push(p);
  }
  return products;
}