import firebase from 'firebase/app';
import 'firebase/functions';

export default {
  // READ
  async getUserCartItems() {
    return (await firebase.app().functions('asia-east2').httpsCallable('cart-getUserCartItems')({ })).data;
  },

  // UPDATE
  async updateUserCartItem(productId: any, quantity: number, variantId: any = null) {
    return await firebase.app().functions('asia-east2').httpsCallable('cart-updateUserCartItem')({ productId, quantity, variantId });
  },
  async addNewCartItem(productId: any, quantity: any = null, variantId: any = null) {
    return await firebase.app().functions('asia-east2').httpsCallable('cart-addNewCartItem')({ productId, quantity, variantId });
  },
  async syncCartItems(newItems: any = [], updatedItems: any = []) {
    return await firebase.app().functions('asia-east2').httpsCallable('cart-syncCartItems')({ newItems, updatedItems });
  }
}