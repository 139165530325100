import { createApp } from 'vue'
import App from '@/App.vue'
import { routes } from '@/app-template/lib/router';
import { createRouter, createWebHistory } from '@ionic/vue-router';

import { IonicVue } from '@ionic/vue';

/* Core CSS required for Ionic components to work properly */
import '@ionic/vue/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/vue/css/normalize.css';
import '@ionic/vue/css/structure.css';
import '@ionic/vue/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/vue/css/padding.css';
import '@ionic/vue/css/float-elements.css';
import '@ionic/vue/css/text-alignment.css';
import '@ionic/vue/css/text-transformation.css';
import '@ionic/vue/css/flex-utils.css';
import '@ionic/vue/css/display.css';

/* Theme variables */
import '@/app-template/lib/theme/variables.css';
import '@/app-template/lib/theme/global.css';
import '@/theme/variables.css';
import '@/theme/global.css';

/* Firebase */
import firebase from 'firebase/app';
import 'firebase/functions';
import 'firebase/auth';
import "firebase/analytics";

/* vue-i18n */
import { createI18n } from 'vue-i18n';
import translationMessages from '@/i18n';
import config from '@/config';

/* Vuex */
import store from '@/store'

import { defineCustomElements } from '@ionic/pwa-elements/loader';
import { Plugins } from '@capacitor/core';

import UserService from '@/services/UserService';
import CommonService from '@/services/CommonService';
import CartService from '@/services/CartService';
import DesignService from '@/services/DesignService';
import OrderService from '@/services/OrderService';

import VueKonva from "vue-konva";

// Call the element loader after the platform has been bootstrapped
defineCustomElements(window);

document.documentElement.style.setProperty("--ion-color-primary", config.primaryColor);

const i18n = createI18n({
  locale: 'zh', // set locale
  messages: translationMessages
});

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
})

const app = createApp(App)
  .use(store)
  .use(i18n)
  .use(IonicVue, { mode: 'md' })
  .use(router)
  .use(VueKonva);


firebase.initializeApp(config.Firebase);
firebase.analytics();
firebase.auth().languageCode = 'zh-HK';

router.isReady().then(() => {
  const { SplashScreen } = Plugins;
  
  SplashScreen.hide();

  /* Retrieve data from AppSheet and save it in Vuex store as state variables */
  CommonService.getPublicData().then(res => {
    store.commit('receivePublicData', res);
    SplashScreen.hide();
  });

  /* Check & get designs from device sessions */
  const sessionNewDesign = JSON.parse(localStorage.getItem(config.newDesignLocalStorageKey) || '{}');
  if (sessionNewDesign.canvasJson) {
    store.commit('receiveNewDesign', sessionNewDesign);
  }

  /* Check & get orders from device sessions */
  const sessionOrderIds = JSON.parse(localStorage.getItem(config.orderIdsLocalStorageKey) || '[]');
  if (sessionOrderIds.length > 0) {
    OrderService.getOrdersByIds(sessionOrderIds).then(res => {
      store.commit('receiveUserOrders', res);
    });
  }

  firebase.auth().onAuthStateChanged((user: any) => {
    if (user) { // logged in
      if (window["plugins"]) {
        window["plugins"].OneSignal.setExternalUserId(user.uid); // Map OneSignal player ID to Firebase User ID
      }
      const redirectPath: any = router.currentRoute.value.query.redirectPath;
      if (redirectPath) {
        router.replace(redirectPath); // redirect to specific page after login
      }
      else if (config.authPages.includes(router.currentRoute.value.path)) {
        router.replace('/home'); // go to home page if the user in auth pages
      }
      store.commit('loadingUser');
      UserService.getLoggedInUser().then(res => {
        if (res && res.roles.includes('admin')) { // for admin users get all designs
          DesignService.getAllDesigns().then(res => {
            store.commit('receiveUserDesigns', res);
          });
        } else {
          store.dispatch('linkSessionDesignsToUser', { userId: res.id });
          DesignService.getUserDesigns().then(res => {
            store.commit('addNewUserDesigns', res);
          });
        }
        // update language & theme settings on login APP
        if (res) {
          document.body.classList.toggle('dark', res.darkTheme == "Y");
          i18n.global.locale = res.preferredLang.toLowerCase();
          store.commit('receiveUser', res);
        } else {
          // user record not exist in DB yet right after registration
          store.commit('receiveUser', {
            id: user.uid,
            email: user.email,
            profilePic: "https://ionicframework.com/docs/demos/api/avatar/avatar.svg",
            preferredLang: i18n.global.locale,
            darkTheme: "N",
          });
        }
      });

      // link session orders to user
      store.dispatch('linkSessionOrdersToUser', { userId: user.uid });

      // get user orders
      store.commit('setLoadingOrders', true);
      OrderService.getUserOrders().then(res => {
        store.commit('addNewUserOrders', res);
      });

      // notifications
      CommonService.getUserNotifications().then(res => {
        store.commit('receiveNotifications', res);
      })
/*
      // User cart items
      store.commit('loadingCartItems');
      CartService.getUserCartItems().then(res => {
        if (store.state.cartItems.length > 0) {
          // sync local cart items to server
          const newItems: any = [], updatedItems: any = [];
          store.state.cartItems.forEach((localItem: any) => {
            if (localItem.isCustomized == 0) { // standard item
              const serverItem = res.find((item: any) => item.productId == localItem.productId);
              if (serverItem) {
                serverItem.quantity = (+serverItem.quantity) + (+localItem.quantity);
                updatedItems.push(serverItem);
              } else {
                res.push(localItem);
                newItems.push(localItem);
              }
            } else { // customized item
              res.push(localItem);
              CartService.addNewCartItem(localItem.designId, localItem); // update DB cart item
            }
          });
          CartService.syncCartItems(newItems, updatedItems);
        }
        store.commit('receiveCartItems', res);
      });
*/
    } else { // logged out
      if (window["plugins"]) {
        window["plugins"].OneSignal.setExternalUserId("");
      }
      store.dispatch('resetUserData');
      //i18n.global.locale = navigator.language.split('-')[0].toLowerCase();
      i18n.global.locale = 'zh';
      const currentPath = router.currentRoute.value.path;
      if (config.memberPages.includes(currentPath)) {
        router.replace({ path: '/login', query: { redirectPath: currentPath } });
      }

      // get list of designs from device sessions
      const sessionDesignIds = JSON.parse(localStorage.getItem(config.designIdsLocalStorageKey) || '[]')
      if (sessionDesignIds.length > 0) {
        DesignService.getDesignsByIds(sessionDesignIds).then(res => {
          store.commit('receiveUserDesigns', res);
        });
      } else {
        store.commit('receiveUserDesigns', { designs: [], designPets: [] });
      }
    }
  });
  app.mount('#app');
  
  /* OneSignal */
  const notificationOpenedCallback = (jsonData: any) => {
    const { additionalData } = (jsonData.notification.payload || {});
    if (additionalData) {
      if (additionalData["app_notification_id"]) {
        // tap notification, go to notification detail page
        if (store.state.loggedIn) {
          CommonService.getUserNotifications().then(res => {
            store.commit('receiveNotifications', res); // refresh list of notifications
          })
          router.replace(`/notifications/${additionalData["app_notification_id"]}`);
        }
      }
    }
  };
  // Set your iOS Settings
  const iosSettings = {};
  iosSettings["kOSSettingsKeyAutoPrompt"] = false;
  iosSettings["kOSSettingsKeyInAppLaunchURL"] = false;
  
  window["plugins"].OneSignal
    .startInit(config.OneSignal.appId)
    .handleNotificationOpened(notificationOpenedCallback)
    .iOSSettings(iosSettings)
    .inFocusDisplaying(window["plugins"].OneSignal.OSInFocusDisplayOption.Notification)
    .endInit();

  // The promptForPushNotificationsWithUserResponse function will show the iOS push notification prompt. We recommend removing the following code and instead using an In-App Message to prompt for notification permission (See step 6)
  window["plugins"].OneSignal.promptForPushNotificationsWithUserResponse((accepted: any) => {
    console.log("User accepted notifications: " + accepted);
  });
});

router.beforeEach((to, from) => {
  if (firebase.auth().currentUser == null) { // guests
    if (config.memberPages.includes(to.path)) {
      //const redirectPath = to.path == '/checkout' ? '/cart' : to.path;
      //return { path: '/login', query: { redirectPath } };
      return { path: '/login' };
    }
  } else { // logged in users
    if (to.path == '/notifications') {
      // update user last click notification tab time
      store.dispatch('updateUserLastCheckNotificationTime');
    }
  }
})