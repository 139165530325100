import { RouteRecordRaw } from 'vue-router';
import Tabs from '@/pages/Tabs.vue'
import config from '@/config';

export const routes: Array<RouteRecordRaw> = [
  ...(config['extraRootRoutes'] || []),
  {
    path: '/login',
    component: () => import('@/pages/LoginPage.vue')
  },
  {
    path: '/register',
    component: () => import('@/pages/RegisterPage.vue')
  },
  {
    path: '/forgot-password',
    component: () => import('@/pages/templates/ForgotPasswordPage.vue')
  },
  {
    path: '/',
    component: Tabs,
    children: [
      ...(config['extraTabRoutes'] || []),
      {
        path: '',
        component: () => import('@/pages/HomePage.vue')
      },
      {
        path: 'home',
        component: () => import('@/pages/HomePage.vue')
      },
      {
        path: 'products',
        name: 'ProductListPage',
        component: () => import('@/pages/templates/ProductListPage.vue'),
      },
      {
        path: 'products/:id',
        name: 'ProductDetailPage',
        component: () => import("@/pages/templates/ProductDetailPage.vue"),
        props: true,
      },
      {
        path: 'liked-products',
        component: () => import('@/pages/templates/UserLikedItemListPage.vue'),
      },
      {
        path: 'cart',
        component: () => import('@/pages/CartPage.vue')
      },
      {
        path: 'checkout',
        name: 'CheckoutPage',
        component: () => import('@/pages/CheckoutPage.vue'),
        props: true,
      },
      {
        path: 'thank-you',
        name: 'ThankYouPage',
        component: () => import('@/pages/ThankYouPage.vue'),
        props: true,
      },
      {
        path: 'posts',
        component: () => import('@/pages/PostListPage.vue'),
      },
      {
        path: "posts/:id",
        name: "PostDetailPage",
        component: () => import("@/pages/PostDetailPage.vue"),
        props: true,
      },
      {
        path: 'my-posts',
        component: () => import('@/pages/templates/UserPostListPage.vue'),
      },
      {
        path: 'profile',
        component: () => import('@/pages/UserProfilePage.vue')
      },
      {
        path: 'orders',
        name: 'OrderListPage',
        component: () => import('@/pages/OrderListPage.vue'),
      },
      {
        path: "orders/:id",
        name: "OrderDetailPage",
        component: () => import("@/pages/OrderDetailPage.vue"),
        props: true,
      },
      {
        path: 'terms-and-conditions',
        component: () => import('@/pages/TermsAndConditionsPage.vue')
      },
      {
        path: 'feedback',
        name: 'FeedbackPage',
        component: () => import('@/pages/templates/FeedbackPage.vue')
      },
    ]
  }
]