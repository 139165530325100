/**
 * Getters for Products
 */
export const getProductById = (state) => (id) => {
  // mainly used in Product Details Page
 return state.allProducts.find(product => product.id == id) || {};
}
export const featuredProducts = (state) => {
  return state.allProducts.filter(product => product.isFeatured == "Y");
}
export const latestProducts = (state) => {
  return state.allProducts.slice(-10); // return latest 10 products (last 10 rows)
}
export const userLikedItems = (state, getters) => {
  return state.allProducts.filter(product => product.likedByUser);
}
export const services = (state) => {
  return state.allProducts.filter(product => product.type == "service");
}
export const allProductTags = (state) => (category = 'all') => {
  const tmpObj = {};
  for (const product of state.allProducts) {
    if (product.tags && (category == 'all' || product.categoryId == category)) {
      for (const tag of product.tags.split(" , ")) {
        tmpObj[tag] = (tmpObj[tag] || 0) + 1;
      }
    }
  }
  return Object.keys(tmpObj).map(tag => {
    return { name: tag, numOfProducts: tmpObj[tag] };
  });
}

/**
 * Getters for Cart Items
 */
export const numOfCartItems = (state) => {
  return state.cartItems.length;
}
export const getCartItem = (state) => (productId, variantId) => {
  variantId = variantId || "";
  return state.cartItems.find(item => item.productId == productId && item.variantId == variantId);
}

/**
 * Getters for Posts
 */
export const userPosts = (state) => {
  const currUserId = state.user.id;
  return state.allPosts.filter(post => post.userId == currUserId);
}
export const getPostById = (state) => (id) => {
  // mainly used in Product Details Page
 return state.allPosts.find(post => post.id == id) || {};
}

/**
 * Getters for Booking
 */
export const getBookingById = (state) => (id) => {
  return state.bookings.find(booking => booking.id == id) || {};
}


/**
 * Getters for Notification
 */
export const getNotificationById = (state) => (id) => {
  return state.allNotifications.find(n => n.id == id) || {};
}
export const numOfNewNotifications = (state, getters) => {
  return state.allNotifications.filter(notification => {
    return state.user.lastCheckNotificationsAt ? 
          new Date(notification.scheduledToSendAt) > new Date(state.user.lastCheckNotificationsAt) : true;
  }).length;
}