
import { utils as parentUtils } from '@/app-template/lib/composables/utils';
import { SplashScreen } from '@capacitor/core';
import { alertController, loadingController, toastController, modalController, } from '@ionic/vue';
import ImageModal from '@/components/ImageModal.vue';
import moment from 'moment';
import { useI18n } from 'vue-i18n';

export function utils() {
  const { t, locale } = useI18n();

  const ORDER_STATUSES = {
    pendingPayment: '待付款',
    paid: '已付款',
    processing: '製作中',
    complete: '已完成',
    cancelled: '已取消',
  }  
  const getOrderStatusColor = (status: string) => {
    if ([ORDER_STATUSES.cancelled].includes(status)) return 'medium';
    if (status == ORDER_STATUSES.pendingPayment) return 'danger';
    if ([ORDER_STATUSES.paid, ORDER_STATUSES.processing].includes(status)) return 'tertiary';
    if (status == ORDER_STATUSES.complete) return 'success';
    return 'light';
  }
  const getDesignItemTotal = (design: any, canvasShapes: any) => {
    const { bottle, numOfPets } = design;
    if (bottle) {
      const bottlePrice = Number(numOfPets == 0 ? bottle['純樽售價'] : bottle[`${numOfPets}隻寵物售價`]);

      const totalFlowerPrices = canvasShapes.reduce((total: any, s: any) => {
        const price = (s.size == 'large' ? s.priceBigSize || s.sellingPrice : s.sellingPrice);
        return s.type == '寵物' ? total : total + Number(price);
      }, 0);
      return bottlePrice + totalFlowerPrices;
    }
    return "";
  }
  const syncFilterBtnPosition = (categorySegmentBtns: any, categoryFilter: any) => {
    if (categorySegmentBtns) {
      for (let i = 0; i < categorySegmentBtns.length; i++) {
        const el: any = categorySegmentBtns[i];
        if (el.value == categoryFilter) {
          setTimeout(() => {
            el.$el.scrollIntoView({
              behavior: 'smooth',
              block: 'nearest',
              inline: 'start'
            });
          }, 50);
          break;
        }
      }
    }
  }
  const arraymove = (arr: any, fromIndex: any, toIndex: any) => {
    const element = arr[fromIndex];
    arr.splice(fromIndex, 1);
    arr.splice(toIndex, 0, element);
  }
  const openModal = async (component: any, componentProps: any) => {
    const modal = await modalController.create({
      component,
      componentProps,
    });
    return modal.present();
  }
  const getProxyImgLink = (imgLink: any) => (imgLink && imgLink.startsWith("http") ? `https://cms.signals.hk/imgproxy.php?url=${encodeURIComponent(imgLink)}` : imgLink);
  const getHTMLImg = async (imgLink: any) => {
    return new Promise((resolve: any) => {
      const IMG_RETRY_MS = 3000; // retry downloading images every X ms on failure
      const img = new window.Image();
      img.onload = resolve(img);
      img.onerror = () => { setTimeout(() => img.src = getProxyImgLink(imgLink), IMG_RETRY_MS); }
      img.crossOrigin = 'Anonymous';
      img.src = getProxyImgLink(imgLink);
    })
  }
  const releaseHTMLCanvases = (canvases: any) => {
    for (const canvas of canvases) {
      if (typeof canvas === "object" && canvas !== null) {
        canvas.width = 1;
        canvas.height = 1;
        const ctx = canvas.getContext('2d');
        ctx && ctx.clearRect(0, 0, 1, 1);
      }
    }
  }
  
  return {
    ...parentUtils(),
    getOrderStatusColor,
    arraymove,
    syncFilterBtnPosition,
    uniqueId: () => Math.random().toString(36).slice(-8),
    openModal,
    openImageModal: async (imageLink: any, caption = "", blankBg = false) => (await openModal(ImageModal, { imageLink, caption, blankBg })),
    closeModal: async (data: any = {}) => (await modalController.dismiss(data)),

    sleep: (s: any) => {
      return new Promise((resolve) => {
        setTimeout(resolve, s * 1000);
      });
    },
    getLocalizedStr: (dataObj: any, keyChi: any, keyEn: any) => {
      return dataObj ? (locale.value == 'zh' ? dataObj[keyChi] : (dataObj[keyEn] || dataObj[keyChi])) : "";
    },
    numberWithCommas: (x: any) => {
      return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },
    addDaysToDate: (date: any, days: any) => {
      days = days == "" ? 1 : days; // default add 1 day
      return (moment(new Date(date))).add(days, 'days');
    },
    getRelativeDate: (date: any) => {
      return moment(new Date(date)).fromNow();
    },
    formatDateString: (d: any) => {
      return d ? moment(new Date(d)).format('YYYY/MM/DD') : "";
    },
    reloadApp: () => {
      SplashScreen.show();
      window.location.reload();
    },
    presentPrompt: async (message: any = "", callback: any) => {
      const alert = await alertController.create({
        message,
        buttons: [
          {
            text: t('cancel'),
            role: 'cancel',
            cssClass: 'secondary',
          },
          {
            text: t('confirm'),
            handler: callback,
          }
        ]
      });
      return alert.present();
    },
    presentAlert: async (msg: string, hideHeader = false) => {
      const obj: any = {
        header: t('errorHeader'),
        message: msg,
        buttons: ['OK']
      }
      if (hideHeader) delete obj.header;
      const alert = await alertController.create(obj);
      return await alert.present();
    },
    presentToast: async(msg: string, duration = 3000, position: any = 'bottom', buttons = []) => {
      const toast = await toastController.create({
        message: msg,
        duration,
        position,
        buttons,
      });
      toast.present();
    },
    presentVerifyCodeInputAlert: async (phone: any, callback: any) => {
      loadingController.dismiss();
      const alert = await alertController.create({
        backdropDismiss: false,
        header: t('RegisterPage.verifyingMobileNumber'),
        subHeader: `${t('RegisterPage.verifyMobileNumberInstruction')} (${phone}).`,
        inputs: [
          {
            name: 'verificationCode',
            type: 'text',
            placeholder: t('RegisterPage.verificationCode'),
            attributes: {
              inputmode: 'numeric',
            }
          },
        ],
        buttons: [
          {
            text: t('cancel'),
            role: 'cancel',
            cssClass: 'secondary',
          },
          {
            text: t('RegisterPage.verify'),
            handler: (value) => {
              if (value.verificationCode) {
                callback(value.verificationCode);
              }
              return false; // not closing the alert
            },
          },
        ],
      });
      await alert.present();
    },

    // CrystalPuteri
    getDesignItemTotal,
    getProxyImgLink,
    getHTMLImg,
    releaseHTMLCanvases,
  };
}