import firebase from 'firebase/app';
import 'firebase/functions';
import { uploadImage, uniqueId, } from '@/services/utils';

export default {
  async insertDesignSubmission({ applicant, design, canvasJson, canvasDataURL }) {
    const designPhotoLink = await uploadImage(canvasDataURL, design.name);
    const payload = { applicant, design, designPhotoLink, canvasJson };
    return (await firebase.app().functions('asia-east2').httpsCallable('design-insertDesignSubmission')(payload)).data;
  },

  async upsertDesignPets(designPets) {
    for (const pet of designPets) {
      if (pet.photos) {
        const photoLinks = []; // image URL
        for (const photo of pet.photos) {
          const link = await uploadImage(photo.base64Data, pet.id || "", "designPet");
          photoLinks.push(link);
        }
        pet.photoLinks = photoLinks;
        delete pet.photos;
      }
    }
    return (await firebase.app().functions('asia-east2').httpsCallable('design-upsertDesignPets')({ designPets })).data;
  },
  async updateDesign({ designId, updatedObj }) {
    return (await firebase.app().functions('asia-east2').httpsCallable('design-updateDesign')({ designId, updatedObj })).data[0];
  },
  async batchUpdateDesigns({ designs }) {
    return (await firebase.app().functions('asia-east2').httpsCallable('design-batchUpdateDesigns')({ designs })).data;
  },
  async upsertDesign({ newDesignId = "", designId = "", name, canvasData, canvasDataURL, extraData = {} }) {
    newDesignId = designId ? "" : (newDesignId || uniqueId());
    let designPhotoLink = "";
    if (canvasDataURL) {
      designPhotoLink = await uploadImage(canvasDataURL, designId || newDesignId, 'design', true);
    }
    return (await firebase.app().functions('asia-east2').httpsCallable('design-upsertDesign')({ newDesignId, designId, name, canvasData, designPhotoLink, extraData, })).data[0];
  },
  async getUserDesigns() {
    return (await firebase.app().functions('asia-east2').httpsCallable('design-getUserDesigns')({ })).data;
  },
  async getAllDesigns() {
    return (await firebase.app().functions('asia-east2').httpsCallable('design-getAllDesigns')({ })).data;
  },
  async getDesignsByIds(designIds: any) {
    return (await firebase.app().functions('asia-east2').httpsCallable('design-getDesignsByIds')({ designIds })).data;
  },

  async deleteDesign(designId: any) {
    return await firebase.app().functions('asia-east2').httpsCallable('design-deleteDesign')({ designId });
  }
}