import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, renderSlot as _renderSlot, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_title = _resolveComponent("ion-title")!
  const _component_ion_toolbar = _resolveComponent("ion-toolbar")!
  const _component_ion_grid = _resolveComponent("ion-grid")!
  const _component_ion_header = _resolveComponent("ion-header")!
  const _component_ion_back_button = _resolveComponent("ion-back-button")!
  const _component_ion_buttons = _resolveComponent("ion-buttons")!
  const _component_ion_icon = _resolveComponent("ion-icon")!
  const _component_ion_button = _resolveComponent("ion-button")!
  const _component_logo_img = _resolveComponent("logo-img")!

  return ($props.titleOnly)
    ? (_openBlock(), _createBlock(_component_ion_header, { key: 0 }, {
        default: _withCtx(() => [
          _createVNode(_component_ion_grid, { fixed: "" }, {
            default: _withCtx(() => [
              _createVNode(_component_ion_toolbar, null, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_title, null, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString($props.title), 1)
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }),
              _renderSlot(_ctx.$slots, "default", {}, undefined, true)
            ]),
            _: 3
          })
        ]),
        _: 3
      }))
    : ($props.parentPath)
      ? (_openBlock(), _createBlock(_component_ion_header, { key: 1 }, {
          default: _withCtx(() => [
            _createVNode(_component_ion_grid, { fixed: "" }, {
              default: _withCtx(() => [
                _createVNode(_component_ion_toolbar, null, {
                  default: _withCtx(() => [
                    _createVNode(_component_ion_title, { class: "ion-text-left header-title" }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString($props.title || $setup.t('back')), 1)
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_ion_buttons, { slot: "start" }, {
                      default: _withCtx(() => [
                        _createVNode(_component_ion_back_button, {
                          class: "back-icon",
                          "default-href": $props.parentPath
                        }, null, 8, ["default-href"])
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                }),
                _renderSlot(_ctx.$slots, "default", {}, undefined, true)
              ]),
              _: 3
            })
          ]),
          _: 3
        }))
      : (_openBlock(), _createBlock(_component_ion_header, { key: 2 }, {
          default: _withCtx(() => [
            _createVNode(_component_ion_grid, { fixed: "" }, {
              default: _withCtx(() => [
                _createVNode(_component_ion_toolbar, null, {
                  default: _withCtx(() => [
                    _createVNode(_component_ion_buttons, { slot: "start" }, {
                      default: _withCtx(() => [
                        _createVNode(_component_ion_button, {
                          href: "https://www.facebook.com/crystalputeriflower.design",
                          target: "_blank"
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_ion_icon, {
                              slot: "icon-only",
                              icon: $setup.logoFacebook
                            }, null, 8, ["icon"])
                          ]),
                          _: 1
                        })
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_ion_title, { class: "ion-text-center" }, {
                      default: _withCtx(() => [
                        _createVNode(_component_logo_img)
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_ion_buttons, { slot: "end" }, {
                      default: _withCtx(() => [
                        _createVNode(_component_ion_button, {
                          href: "https://www.instagram.com/crystalputeri_flower.design",
                          target: "_blank"
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_ion_icon, {
                              slot: "icon-only",
                              icon: $setup.logoInstagram
                            }, null, 8, ["icon"])
                          ]),
                          _: 1
                        })
                      ]),
                      _: 1
                    }),
                    (!$props.hideIcons)
                      ? (_openBlock(), _createBlock(_component_ion_buttons, {
                          key: 0,
                          slot: "primary"
                        }, {
                          default: _withCtx(() => [
                            ($props.showSearchIcon)
                              ? (_openBlock(), _createBlock(_component_ion_button, {
                                  key: 0,
                                  onClick: _cache[0] || (_cache[0] = ($event: any) => ($setup.onClickSearchBtn()))
                                }, {
                                  default: _withCtx(() => [
                                    _createVNode(_component_ion_icon, {
                                      slot: "icon-only",
                                      icon: $setup.searchOutline
                                    }, null, 8, ["icon"])
                                  ]),
                                  _: 1
                                }))
                              : _createCommentVNode("", true),
                            ($setup.userLoggedIn)
                              ? (_openBlock(), _createBlock(_component_ion_button, {
                                  key: 1,
                                  onClick: _cache[1] || (_cache[1] = ($event: any) => ($setup.openNotificationListModal()))
                                }, {
                                  default: _withCtx(() => [
                                    _createVNode(_component_ion_icon, {
                                      slot: "icon-only",
                                      icon: $setup.notificationsOutline
                                    }, null, 8, ["icon"])
                                  ]),
                                  _: 1
                                }))
                              : _createCommentVNode("", true)
                          ]),
                          _: 1
                        }))
                      : _createCommentVNode("", true)
                  ]),
                  _: 1
                }),
                _renderSlot(_ctx.$slots, "default", {}, undefined, true)
              ]),
              _: 3
            })
          ]),
          _: 3
        }))
}